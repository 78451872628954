import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  Button as MUIButton,
  Box,
  Switch,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  Button,
  Card,
  ConfirmAlert,
  ContainerLayout,
  defaultTextInputStyle,
  Input,
  ToasterUtils,
} from 'common';
import { promptBuilder } from 'prompts/prompts';
import React, { useState } from 'react';
import { FormLabelWithHelperText } from 'pages/ContextualNudge/components';
import { CTASection } from './CTASection';
import { RichTextEditor } from 'common/components/RichTextEditor/RichTextEditor';
import { htmlToMarkdown } from 'common/components/RichTextEditor/utils';
import {
  createPrompt,
  CreatePromptParams,
  deletePrompt,
  PromptAction,
  uploadPromptUserFile,
} from './api';
import { useParams } from 'react-router-dom';
import { RoutePath } from 'types/routes';
import { SuccessConfirm } from 'common/components/SuccessConfirm';
import history from 'utils/history';
import { useAuthState } from 'common/hooks/useAuthState';
import { PromptBuilderFormTypes, PromptCTATypes } from './PromptBuilder.types';
import { SearchAndSelect } from 'pages/GroovVoice/Components/Common/SearchAndSelect';
import usePromptAttributes from './usePromptAttributes';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import usePrompt from './hooks/usePrompt';
import moment from 'moment';
import { clearLocalPrompt } from './promptBuilder.slice';
import { useAppDispatch } from 'store';

type FormState = 'idle' | 'loading' | 'success' | 'error';

const defaultPrompt: PromptBuilderFormTypes = {
  id: '',
  name: '',
  body: '',
  ctaType: PromptCTATypes.openEndedText,
  userList: [],
  promptDateTime: '',
  categoryId: '',
  isGroovVoice: false,
  isConfidential: true,
  promptTags: [],
};

export function PromptPreview() {
  const classes = useStyles();
  const tagStyles = makeStyles(defaultTextInputStyle)();
  const params = useParams<{ id: string }>();
  const [formState, setFormState] = useState<FormState>('idle');
  const [confirmAlert, setConfirmAlert] = useState(false);
  const authState = useAuthState();
  const { categoryOptions, tags } = usePromptAttributes();
  const appDispatch = useAppDispatch();
  const { isLoading, prompt, isLocalPrompt } = usePrompt(params.id, tags);

  const values = prompt ?? defaultPrompt;

  if (isLoading) {
    return (
      <ContainerLayout>
        <Card className={classes.loadingIndicator}>
          <AnimatedSpinner />
        </Card>
      </ContainerLayout>
    );
  }

  const handleFormSubmit = async () => {
    if (values.id === '') {
      return;
    }

    setFormState('loading');
    const markdown = htmlToMarkdown(values.body);
    const orgId = authState.orgId;
    const triggerId = values.id;
    const promptActions: PromptAction[] =
      values?.ctaValue?.map((value) => {
        return { action: value.name, response: htmlToMarkdown(value.description) };
      }) ?? [];
    const promptTags = values.promptTags?.map((tag) => tag.label);
    const responseToOpenText = values.responseToOpenText
      ? htmlToMarkdown(values.responseToOpenText)
      : undefined;

    const createPromptParams: CreatePromptParams = {
      triggerId: triggerId,
      promptName: values.name,
      promptText: markdown,
      promptActions: promptActions,
      categoryId: values.categoryId || '9', // default to the "Other" category (this should never be used)
      isGroovVoice: values.isGroovVoice,
      isConfidential: values.isConfidential,
      responseToOpenText: responseToOpenText,
      openTextResponse:
        values.ctaType === PromptCTATypes.openEndedText ||
        values.ctaType === PromptCTATypes.customAndOpenEndedText ||
        values.ctaType === PromptCTATypes.sixPtScaleAndOpenEndedText,
      promptTags: promptTags,
      promptCTAType: values.ctaType,
      scheduleTime: new Date(values.promptDateTime!).toISOString(),
    };

    const uploadUserFileParams = {
      triggerId: triggerId,
      promptName: values.name,
      scheduleTime: new Date(values.promptDateTime!),
      orgId: orgId,
      users: values.userList,
    };
    await uploadPromptUserFile(uploadUserFileParams);
    const res = await createPrompt(createPromptParams);
    if (res.status === 200) {
      appDispatch(clearLocalPrompt());
      setFormState('success');
    } else {
      setFormState('error');
    }
  };

  const handleGoBack = () => {
    history.push(RoutePath.PromptBuilder);
  };

  const handleDeletePrompt = async () => {
    const response = await deletePrompt(values.id);
    if (response) {
      handleGoBack();
    } else {
      ToasterUtils.toast(promptBuilder.toastErrorMsg, 'error', promptBuilder.toastErrorTitle);
    }
    setConfirmAlert(false);
  };

  return (
    <ContainerLayout>
      <Card>
        <Box className={classes.heading}>
          <Typography
            className={classes.manageNudgeCompany}
            variant="h2"
            data-testid="nudges_page_title">
            {promptBuilder.previewPrompt}
          </Typography>
          <Typography color="textSecondary">{values.id}</Typography>
        </Box>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormLabelWithHelperText
                label={promptBuilder.promptName}
                description={promptBuilder.promptNameDescription}
              />
              <Input
                fullWidth
                name="name"
                value={values.name}
                placeholder={promptBuilder.promptName}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={3} direction="column" className={classes.flexSpaceBetween}>
              <FormLabelWithHelperText
                label={promptBuilder.promptGroovVoiceSwitch}
                description={promptBuilder.promptGroovVoiceSwitchDescription}
              />
              <Box height={50} display="flex" alignItems="center" mx={2}>
                <Typography>Don&apos;t Report</Typography>
                <Switch
                  name="isGroovVoice"
                  value={values.isGroovVoice}
                  checked={values.isGroovVoice}
                  disabled
                />
                <Typography>Report</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} direction="column" className={classes.flexSpaceBetween}>
              <FormLabelWithHelperText
                label={promptBuilder.promptResponseConfidentialSwitch}
                description={promptBuilder.promptResponseConfidentialSwitchDescription}
              />
              <Box height={50} display="flex" alignItems="center" mx={2}>
                <Typography>No</Typography>
                <Switch
                  name="isConfidential"
                  value={values.isConfidential}
                  checked={values.isConfidential}
                  disabled
                />
                <Typography>Yes </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabelWithHelperText
                label={promptBuilder.promptDateAndTime}
                description={promptBuilder.promptDateAndTimeDescription}
              />
              <Input
                fullWidth={true}
                name="promptDateTime"
                value={values.promptDateTime}
                placeholder={promptBuilder.promptDateAndTime}
                type="datetime-local"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabelWithHelperText
                label={promptBuilder.promptCategory}
                description={promptBuilder.promptCategoryDescription}
              />
              <Box my={4}>
                <Select
                  value={values.categoryId}
                  variant="outlined"
                  name="categoryId"
                  disabled
                  className={classes.select}
                  fullWidth>
                  {categoryOptions.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabelWithHelperText
                label={'Tags'}
                description={'Select some tags or create your own'}
              />
              <SearchAndSelect
                hideSelected
                disabled
                options={[]}
                value={values.promptTags}
                className={tagStyles.root}
                classes={{ inputRoot: tagStyles.inputRoot }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={promptBuilder.promptBody}
                description={promptBuilder.promptBodyDescription}
              />
              <Box mt={2} />
              <RichTextEditor
                value={values.body}
                name="body"
                showToolbar={false}
                maxHeight={300}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={promptBuilder.promptCTA}
                description={promptBuilder.promptCTADescription}
              />
              <RadioGroup defaultValue={values.ctaType} name="ctaType">
                <FormControlLabel
                  value={PromptCTATypes.custom}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>{promptBuilder.promptCTACustom}</Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTACustomDescription}
                      </Typography>
                    </Box>
                  }
                  disabled
                />
                <FormControlLabel
                  value={PromptCTATypes.sixPtScale}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>{promptBuilder.promptCTASixPointScale}</Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTASixPointScaleDescription}
                      </Typography>
                    </Box>
                  }
                  disabled
                />
                <FormControlLabel
                  value={PromptCTATypes.openEndedText}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>{promptBuilder.promptCTAOpenEndedText}</Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTAOpenEndedTextDescription}
                      </Typography>
                    </Box>
                  }
                  disabled
                />
                <FormControlLabel
                  value={PromptCTATypes.customAndOpenEndedText}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>{promptBuilder.promptCTACustomAndOpenEndedText}</Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTACustomAndOpenEndedTextDescription}
                      </Typography>
                    </Box>
                  }
                  disabled
                />
                <FormControlLabel
                  value={PromptCTATypes.sixPtScaleAndOpenEndedText}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>
                        {promptBuilder.promptCTASixPointScaleAndOpenEndedText}
                      </Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTASixPointScaleAndOpenEndedTextDescription}
                      </Typography>
                    </Box>
                  }
                  disabled
                />
              </RadioGroup>

              {(values.ctaType === PromptCTATypes.custom ||
                values.ctaType === PromptCTATypes.sixPtScale ||
                values.ctaType === PromptCTATypes.customAndOpenEndedText ||
                values.ctaType === PromptCTATypes.sixPtScaleAndOpenEndedText) && (
                <CTASection value={values.ctaValue} showToolbar={false} disabled />
              )}
            </Grid>
            {(values.ctaType === PromptCTATypes.customAndOpenEndedText ||
              values.ctaType === PromptCTATypes.sixPtScaleAndOpenEndedText) && (
              <Grid item xs={12}>
                <FormLabelWithHelperText
                  label={promptBuilder.promptResponseToOpenTextAndAction}
                  description={promptBuilder.promptResponseToOpenTextAndActionDescription}
                />
                <Box mt={2} />
                <RichTextEditor
                  value={values.responseToOpenText}
                  name="responseToOpenText"
                  maxHeight={180}
                  showToolbar={false}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={promptBuilder.promptUserList}
                description={promptBuilder.promptUserListDescription}
              />
              <Card className={classes.userList}>
                <Box maxHeight={300} overflow="scroll">
                  {values.userList.map((user) => (
                    <Typography key={user.Email + user.OrgId}>
                      {user.Email} <span className={classes.spacing}>{user.OrgId}</span>
                    </Typography>
                  ))}
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} className={classes.flex} justifyContent="flex-end">
              <MUIButton
                className={classes.cancelPrompt}
                variant="text"
                onClick={() => history.goBack()}
                disabled={formState === 'loading' || formState === 'success'}>
                {isLocalPrompt ? promptBuilder.promptCancel : promptBuilder.promptBack}
              </MUIButton>
              {isLocalPrompt && (
                <Button
                  className={classes.createPrompt}
                  onClick={handleFormSubmit}
                  disabled={formState === 'loading' || formState === 'success'}>
                  {promptBuilder.promptSchedule}
                </Button>
              )}
              {moment(values?.promptDateTime).isAfter() && !isLocalPrompt && (
                <Button className={classes.deletePrompt} onClick={() => setConfirmAlert(true)}>
                  {promptBuilder.deletePrompt}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Card>
      {formState === 'success' && (
        <SuccessConfirm
          open={formState === 'success'}
          title={promptBuilder.promptSuccessMessage}
          description={promptBuilder.promptSuccessDescription}
          confirmButtonText={promptBuilder.confirmButtonText}
          handleConfirm={() => handleGoBack()}
        />
      )}
      <ConfirmAlert
        open={confirmAlert}
        title="Are you sure you want to delete this scheduled prompt?"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        handleCancel={() => setConfirmAlert(false)}
        handleConfirm={() => handleDeletePrompt()}
      />
    </ContainerLayout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(9),
    },
    manageNudgeCompany: {
      marginTop: theme.spacing(3),
    },
    createPrompt: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(6),
      minWidth: theme.spacing(42),
    },
    deletePrompt: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(6),
      minWidth: theme.spacing(42),
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        background: theme.palette.error.main,
      },
    },
    cancelPrompt: {
      marginRight: theme.spacing(4),
      minWidth: theme.spacing(42),
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(6),
      textTransform: 'none',
    },
    flex: {
      marginTop: theme.spacing(10),
      display: 'flex',
    },
    radio: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    userList: {
      marginTop: theme.spacing(4),
    },
    select: {
      borderRadius: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: 'white',
    },
    spacing: {
      paddingLeft: theme.spacing(1),
    },
    flexSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    loadingIndicator: {
      height: '80vh',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
  })
);
