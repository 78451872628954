import './RichTextEditor.css';
import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Typography, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

// Editor Configs
const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Delete'];
const allowedCombinations = ['x', 'c', 'v', 'a'];
const plugins = [
  'autolink',
  'lists',
  'link',
  'preview',
  'code',
  'fullscreen',
  'wordcount',
  'emoticons',
];
const toolbar = 'bold italic strikethrough| link | bullist numlist | emoticons';

const useStyles = makeStyles((theme) => ({
  editorWrapper: {
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 11,
    '&.error': {
      borderColor: theme.palette.error.main,
    },
  },
  errorContainer: { marginTop: '10px' },
}));

interface Props {
  value?: string;
  onChange?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  onBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  showToolbar?: boolean;
  maxHeight?: number;
  name: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  maxCharacters?: number;
}

export const RichTextEditor = ({
  value,
  onBlur,
  onChange,
  showToolbar = true,
  maxHeight,
  name,
  disabled,
  error,
  helperText,
  maxCharacters = 1500,
}: Props) => {
  const classes = useStyles();
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const initializeEditor = (_: any, editor: TinyMCEEditor) => {
    editorRef.current = editor;
    const container: HTMLElement | null = editor
      .getContainer()
      .querySelector('button.tox-statusbar__wordcount');
    if (container) {
      container.click();
    }
  };

  return (
    <React.Fragment>
      <div className={clsx(classes.editorWrapper, { error })}>
        <Editor
          onInit={initializeEditor}
          init={{
            max_height: maxHeight,
            menubar: false,
            statusbar: false,
            plugins: plugins,
            toolbar: showToolbar ? toolbar : false,
          }}
          value={value}
          onEditorChange={(e) => {
            onChange?.({ target: { name, value: e } });
          }}
          onBlur={(e) => {
            onBlur?.({ target: { name, value: e } });
          }}
          textareaName={name}
          disabled={disabled}
        />
      </div>
      {!disabled && (
        <div className={classes.errorContainer}>
          {error && <FormHelperText error>{helperText}</FormHelperText>}
          <Typography variant="subtitle1">
            Remaining characters: {maxCharacters - (value?.length ?? 0)}
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
};
