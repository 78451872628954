import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Divider,
} from '@material-ui/core';
import { SearchBar } from 'common';
import moment from 'moment';
import * as React from 'react';
import { useAppSelector } from 'store';
import { makeStyles } from '@material-ui/core/styles';
import { orderBy } from 'lodash';
import { PromptSummary } from './PromptBuilder.types';
import { RoutePath } from 'types/routes';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  tableContainer: {
    minHeight: '50vh',
    maxHeight: '51vh',
    overflowY: 'auto',
  },
  tableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },

  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
}));

const columns: {
  field: keyof PromptSummary;
  headerName: string;
  width: number;
  valueFormatter?: (value: unknown) => string;
}[] = [
  {
    field: 'id',
    headerName: 'Prompt ID',
    width: 120,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 180,
  },
  {
    field: 'created_on',
    headerName: 'Date Created',
    width: 140,
    valueFormatter: (value: unknown) => {
      if (typeof value === 'string') {
        const date = moment(value);
        if (date.isValid()) {
          return date.local().format('DD/MM/YY');
        }
      }
      return '';
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
  },
  {
    field: 'prompt',
    headerName: 'Prompt Body',
    width: 250,
  },
  {
    field: 'promptType',
    headerName: 'Prompt CTA',
    width: 150,
  },
  {
    field: 'prompt_schedule_time',
    headerName: 'Date Time Sent',
    width: 170,
    valueFormatter: (value: unknown) => {
      if (typeof value === 'string') {
        const date = moment(value);
        if (date.isValid()) {
          return date.local().format('DD/MM/YY h:mmA');
        }
      }
      return '';
    },
  },
];

export function PromptTable() {
  const { prompts, isLoading } = useAppSelector((state) => ({
    prompts: state.promptBuilder.allPrompts,
    isLoading: state.promptBuilder.loading,
  }));

  const [paginationModel, setPaginationModel] = React.useState<number>(10);
  const [page, setPage] = React.useState(0);
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = React.useState<string>('id');
  const [records, setRecords] = React.useState(prompts);

  const { push } = useHistory();
  const handleRowClick = async (id: number) => {
    push(`${RoutePath.PromptPreview}/${id}`);
    return;
  };

  React.useEffect(() => {
    setRecords(prompts);
  }, [prompts]);

  const handleRequestSort = (property: string) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const sortedRecords = React.useMemo(() => {
    return orderBy(records, [sortBy], [sortDirection]);
  }, [records, sortBy, sortDirection]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationModel(parseInt(event.target.value, 10));
    setPage(0);
  };
  const searchKeys: (keyof PromptSummary)[] = ['name', 'id', 'prompt', 'promptType', 'status'];

  const classes = useStyles();

  return (
    <Box position="relative" flex={1} display="flex" flexDirection="column" overflow="hidden">
      <Box my={4} width="50%">
        <SearchBar
          records={prompts}
          searchKeys={searchKeys}
          showSearchIcon
          setResults={(res) => {
            setRecords(res);
            if (page !== 0) {
              setPage(0);
            }
          }}
          shouldClearQuery={false}
        />
      </Box>
      <Paper style={{ width: '100%', flex: 1 }}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    style={{ width: column.width, maxWidth: column.width }}>
                    <TableSortLabel
                      active={sortBy === column.field}
                      direction={sortBy === column.field ? sortDirection : 'asc'}
                      onClick={() => handleRequestSort(column.field)}>
                      {column.headerName}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRecords
                .slice(page * paginationModel, page * paginationModel + paginationModel)
                .map((row) => (
                  <TableRow
                    key={row.id}
                    className={classes.tableRow}
                    component={Box}
                    onClick={() => handleRowClick(row.id)}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        className={classes.tableCell}
                        style={{
                          width: column.width,
                          maxWidth: column.width,
                        }}>
                        {column.valueFormatter
                          ? column.valueFormatter(row[column.field])
                          : row[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={sortedRecords.length}
          rowsPerPage={paginationModel}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {isLoading && (
        <Box className={classes.loadingOverlay}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
