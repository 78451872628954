import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, makeStyles, Typography, Button, Grid } from '@material-ui/core';
import { PromptTags } from '../Common/PromptTags';
import history from 'utils/history';
import { format } from 'date-fns';
import Markdown from 'markdown-to-jsx';
import { AnalyticsInfo } from '../Common/AnalyticsInfo';
import { PromptDetails, PromptItemType } from '../../GroovVoice.d';
import { getPromptTags, getAdditionalAnalyticsLayout } from 'pages/GroovVoice/GroovVoice.utils';
import { PromptCTATypes } from 'pages/PromptBuilder';
import clsx from 'clsx';

export function PromptInformation({
  prompt,
  promptDetails,
}: Readonly<{
  prompt: PromptItemType;
  promptDetails?: PromptDetails;
}>) {
  const classes = useStyles();

  const promptResponsesDetails = promptDetails?.responses;
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const promptBodyRef = useRef<HTMLDivElement>(null);

  const analyticsLayout = useMemo(() => {
    if (promptDetails) {
      return getAdditionalAnalyticsLayout(
        prompt?.properties?.prompt_cta_type as PromptCTATypes,
        promptDetails,
        promptResponsesDetails ?? []
      );
    }
  }, [promptResponsesDetails, prompt, promptDetails]);

  useEffect(() => {
    const checkOverflow = () => {
      const element = promptBodyRef.current;
      if (element) {
        setIsOverflowing(element.scrollHeight > element.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, [promptBodyRef]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box>
      <Grid direction="row" container alignItems="center">
        <Button variant="text" size="small" onClick={handleGoBack}>
          <Typography className={classes.promptBreadcrumbsLink}>Prompts</Typography>
        </Button>
        <Typography className={classes.promptBreadcrumbsText}>/ {prompt.promptName}</Typography>
      </Grid>
      <Box mt={8}>
        <PromptTags tags={getPromptTags(prompt)} />
        <Typography variant="h2" className={classes.spacing}>
          {prompt.promptName}
        </Typography>
        <Typography className={classes.promptId}>{prompt.promptId}</Typography>
        {prompt.lastSentUtc && (
          <Typography variant="body2" className={classes.promptDateTime}>
            <span className={classes.boldText}>Sent</span>
            {format(new Date(prompt.lastSentUtc), 'yyyy-MM-dd hh:mm a')}
          </Typography>
        )}
        <Typography className={classes.boldLabel}>Prompt</Typography>
        <div
          ref={promptBodyRef}
          className={clsx(
            classes.promptBody,
            expanded && classes.expanded,
            isOverflowing && classes.gradientOverlay
          )}>
          <Typography>
            <Markdown>{prompt.promptText}</Markdown>
          </Typography>
        </div>
        {isOverflowing && (
          <Button onClick={handleExpandClick} className={classes.expandButton}>
            {expanded ? 'See less' : 'See more'}
          </Button>
        )}
      </Box>
      <Box mt={4} className={classes.analyticsInfoContainer}>
        {analyticsLayout?.map((section) => (
          <AnalyticsInfo
            key={section.label}
            label={section.label}
            value={section.value}
            subValue={section.subValue}
          />
        ))}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  promptBreadcrumbsText: {
    textTransform: 'none',
    color: '#333',
  },
  promptBreadcrumbsLink: {
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  promptId: {
    marginBottom: 16,
  },
  promptDateTime: {
    fontSize: 16,
    marginBottom: 16,
    fontWeight: 400,
    color: 'black',
  },
  boldText: {
    fontWeight: 'bolder',
    paddingRight: 16,
  },
  boldLabel: {
    fontSize: 16,
    marginBottom: 16,
    color: 'black',
    fontWeight: 'bolder',
  },
  promptBody: {
    minHeight: '50px',
    maxHeight: '160px',
    overflowY: 'hidden',
    position: 'relative',
    color: 'black',
  },
  gradientOverlay: {
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '32px',
      background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
    },
  },
  expanded: {
    maxHeight: 'none',
    overflowY: 'visible',
    '&::after': {
      content: 'none',
    },
  },
  expandButton: {
    display: 'block',
    marginTop: theme.spacing(2),
    textTransform: 'none',
    color: 'black',
    textAlign: 'left',
    padding: 0,
  },
  analyticsInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  spacing: {
    marginTop: 12,
  },
}));
